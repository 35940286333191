<script>
import {mapGetters} from "vuex";

export default {
  name: "PlayerActionBadge",
  props: {
    player: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      title: '',
      minLife: 3000,
      lastActionTime: 0,
      resetTimer: null,
      actionClass: '',
      actions: {
        fold: '폴드',
        check: '체크',
        call: '콜',
        bet: '벳',
        raise: '레이즈',
        'allin!': '올인!'
      },
    }
  },
  computed: {
    ...mapGetters('game', ['lastAction'])
  },
  watch: {
    lastAction: {
      deep: true,
      immediate: true,
      handler: 'onChangeLastAction'
    }
  },
  methods: {
    onChangeLastAction() {
      if (!this.lastAction) {
        return;
      }

      const {sn, action} = this.lastAction;

      if (!action || this.player.sn !== sn) {
        this.title = '';
        return;
      }

      let _action = action.toLowerCase();
      this.actionClass = _action === 'raise' || _action === 'allin!' ? 'bet' : _action;
      this.title = this.actions[_action];
    }
  }
}
</script>

<template>
  <transition appear name="scale-up" mode="out-in">
    <div v-show="!!title" class="area_bet" :class="[actionClass]">
      <span>{{ title }}</span>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.scale-up-enter-active {
  position: absolute;
  transition: all .3s ease-in-out;
}

.scale-up-enter, .scale-up-appear /* .fade-leave-active in <2.1.8 */
{
  position: absolute;
  transform-origin: center;
  transform: scale(1.5);
}
</style>